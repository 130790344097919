
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import Gallery, { UploadPhotoEvent, DeletePhotoEvent } from '@/components/Gallery.vue';

import { PartialVideo } from '@/interfaces/Video';
import { useI18n } from 'vue-i18n';

import {
  getVideo,
  createVideoPhoto,
  deleteVideoPhoto,
  IntBoolean
} from '@/services/api';

export default defineComponent({
  components: {
    Gallery
  },
  setup() {
    const video = ref<PartialVideo>({});
    const videoId = useRoute().params.id as string;
    const page = useRoute().query.page as string;
    const { t } = useI18n();

    const fetchVideos = async() => {
      const { data } = await getVideo({ videoId });
      video.value = data;
    };

    const handleUploadPhoto = async({ index, photo }: UploadPhotoEvent) => {
      console.log({ index, photo });
      await createVideoPhoto({
        videoId,
        data: {
          image: photo.path
        }
      });

      fetchVideos();
    };

    const handleDeletePhoto = async({ index }: DeletePhotoEvent) => {
      console.log({ index });

      await deleteVideoPhoto({
        videoId,
        photoId: `${video.value.photos[index].id}`
      });

      fetchVideos();
    };

    onMounted(() => {
      fetchVideos();
    });

    return {
      page,
      video,
      handleUploadPhoto,
      handleDeletePhoto,
      t,
      IntBoolean
    };
  }
});
