
import { defineComponent, ref, PropType, toRefs, watch, onMounted } from 'vue';

import ImageUploader, { UploadImageEvent } from './ImageUploader.vue';

export interface UploadPhotoEvent {
  index: number
  photo: UploadImageEvent
};

export interface DeletePhotoEvent {
  index: number
};

export default defineComponent({
  components: {
    ImageUploader
  },
  props: {
    sources: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    // video gallery or model gallery
    type: {
      type: String,
      required: true
    },
    // set limit photo count
    limitPhotoCount: {
      type: Number,
      default: 8
    }
  },
  emits: ['upload', 'delete'],
  setup(props, { emit }) {
    const { sources } = toRefs(props);
    const photoSources = ref<string[]>([...sources.value]);
    const { limitPhotoCount } = toRefs(props);

    const checkPhotoCount = (sources) => {
      if (sources.length < limitPhotoCount.value) {
        photoSources.value = [...sources, ''];
      } else {
        photoSources.value = [...sources];
      }
    };

    watch(sources, (sources) => {
      checkPhotoCount(sources);
    });

    onMounted(() => {
      checkPhotoCount(props.sources);
    });

    const handelUpload = (event: UploadPhotoEvent) => {
      emit('upload', event);
    };

    const handleDelete = ({ index }: DeletePhotoEvent) => {
      emit('delete', { index });
    };

    return {
      photoSources,
      handleDelete,
      handelUpload
    };
  }
});
